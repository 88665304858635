import {
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useInView } from 'react-intersection-observer'
import styled, { css } from 'styled-components'

import nanoid from '@utils/random'
import config from '@config'
import useUser, { User } from '@hooks/useUser'
import useAuthentication from '@hooks/useAuthentication'
import useDisableThirdPartyScripts from '@hooks/useDisableThirdPartyScripts'
import { mobileCSS } from '@measures/responsive'
import useViewportTracking from '@hooks/useViewportTracking'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import { stripHtml } from '@hooks/useTracking/utils'
import { useRouter } from 'next/router'
import useCMPCookieCategories from '@hooks/useCMPCookieCategories'
import CMPPlaceholder from '@components/CMPPlaceholder'
import useRefDelegate from '@hooks/useRefDelegate'

export interface EmbeddedContentType {
  url?: string
  ['max-width']?: number
  ['max-height']?: number
  ['max-height-mobile']?: number
  scrollingEnabled?: boolean
  htmlCode?: string
  withSSO?: boolean
  parentProps?: {
    inline?: boolean
  }
}

export interface EmbeddedContentProps extends EmbeddedContentType {
  user?: User
  injectHtml?: string
  login?: any
  style?: any
  onScrollToRequest?: (offset: number) => void
}

export interface EmbeddedContentWebProps extends EmbeddedContentProps {
  heightFieldName?: string
  idFieldName?: string
  id?: string
  maxWidth?: number
  maxHeight?: number
  maxHeightMobile?: number
  trackImpression?: boolean
  title?: string
  contentId?: string
  className?: string
}

export interface CMPAwareEmbeddedContentProps extends EmbeddedContentWebProps {
  bypassCMP?: boolean
}

export type GenericMessage = {
  iframeId: string
}

export interface NavigateToMessage extends GenericMessage {
  type: 'navigateTo'
  blick_event_type: 'navigateTo'
  href: string
}

export interface InWebviewNavigationMessage extends GenericMessage {
  type: 'IN_WEBVIEW_NAVIGATION'
  blick_event_type: 'IN_WEBVIEW_NAVIGATION'
}

export interface CMPEnableAllCategoriesMessage extends GenericMessage {
  type: 'CMP_ENABLE_ALL_CATEGORIES'
  blick_event_type: 'CMP_ENABLE_ALL_CATEGORIES'
}

export interface CMPShowMoreInfoMessage extends GenericMessage {
  type: 'CMP_SHOW_MORE_INFO'
  blick_event_type: 'CMP_SHOW_MORE_INFO'
}

export interface ResizeMessage extends GenericMessage {
  type: 'resize'
  blick_event_type: 'resize'
  height: number
  width: number
}

export interface IFrameReadyMessage extends GenericMessage {
  type: 'iframeReady'
  blick_event_type: 'iframeReady'
  iframeReady: true
}

export interface LoginActionMessage extends GenericMessage {
  type: undefined
  blick_event_type: undefined
  loginAction: true
  ssoTrackingSource?: string
}

export interface TrackActionMessage extends GenericMessage {
  type: 'TRACK'
  blick_event_type: 'TRACK'
  name: string
  data: Record<string, unknown>
}

export interface LoginMessage extends GenericMessage {
  type: 'LOGIN'
  blick_event_type: 'LOGIN'
  loginCase?: string
  source?: string
}

export interface NavigateMessage extends GenericMessage {
  type: 'NAVIGATE'
  blick_event_type: 'NAVIGATE'
  href: string
}

export type PostMessage =
  | NavigateToMessage
  | InWebviewNavigationMessage
  | CMPEnableAllCategoriesMessage
  | CMPShowMoreInfoMessage
  | ResizeMessage
  | IFrameReadyMessage
  | LoginActionMessage
  | TrackActionMessage
  | LoginMessage
  | NavigateMessage

const {
  iframe: { heightAdjustmentScript, allowedOriginsToReceivePostMessage },
} = config

type StyledEmbeddedContentContainer = Pick<
  EmbeddedContentWebProps,
  'maxHeight' | 'maxHeightMobile'
>

const StyledEmbeddedContentContainer = styled.div<StyledEmbeddedContentContainer>`
  ${({ maxHeight, maxHeightMobile }) => css`
    width: 100%;
    position: relative;
    height: ${maxHeight ?? 200}px;

    ${mobileCSS(css`
      height: ${maxHeightMobile ?? 200}px;
    `)}
  `}
`

const StyledIFrame = styled.iframe`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: none;
  height: 100%;
  width: 1px;
  min-width: 100%;
  margin: 0;
`

const EmbeddedContent: FunctionComponent<EmbeddedContentWebProps> = ({
  url,
  scrollingEnabled,
  htmlCode,
  'max-height': maxHeight,
  'max-height-mobile': maxHeightMobile,
  withSSO,
  trackImpression,
  title,
  contentId,
  className,
}) => {
  const router = useRouter()
  const disableThirdPartyScripts = useDisableThirdPartyScripts()
  const user = useUser()
  const iframeIdRef = useRef<string>('')
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const iframeEmbeddedCodeWasInjected = useRef<boolean>(false)
  const [iframeError, setIFrameError] = useState<boolean>(false)

  const setupHtmlCodeIframe = useCallback<(node: HTMLIFrameElement) => void>(
    (node) => {
      if (!iframeEmbeddedCodeWasInjected.current && htmlCode && node) {
        // If the iframe is just an html code we have to add the content
        // and the height adjustment script to the body of the iframe
        const document = node.contentDocument
        if (document) {
          //! A lot of times the html code for the iframe is edited wrongly on the CMS.
          //! That results in errors in the following piece of code, which we can do nothing about.
          //! We now handle these errors so they don't pollute Sentry logs.
          try {
            const blickDataLayerScript = document.createElement('script')
            blickDataLayerScript.type = 'text/javascript'
            blickDataLayerScript.text = 'window.blickDataLayer=[];'

            const script = document.createElement('script')
            script.type = 'text/javascript'
            script.src = heightAdjustmentScript

            // Due to some special iframes with embedded scripts we cannot
            // just call: document.body.innerHTML = htmlCode, so we have to use
            // the good ol' document.write
            document.open()
            document.write(htmlCode)
            document.head.append(blickDataLayerScript)
            document.body.append(script)
            document.close()
            iframeEmbeddedCodeWasInjected.current = true
          } catch (err) {
            try {
              ;(err as Error).message =
                'The following iframe embedded code is malformated and needs to be changed in the CMS:\n\n' +
                '\n\n===== Beginning of code =====\n\n' +
                htmlCode +
                '\n\n===== End of code =====\n\n'

              console.error(err)
            } catch (err) {
              // Do nothing
            }

            setIFrameError(true)
          }
        }
      }
    },
    [htmlCode]
  )

  const setIframeRef: (node?: HTMLIFrameElement | null) => void = (node) => {
    if (node) {
      iframeRef.current = node
      setupHtmlCodeIframe(node)
    }
  }

  const [loadNow, setLoadNow] = useState(false)

  const doLoadNow = useCallback<(isInView: boolean) => void>((isInView) => {
    if (isInView) {
      if (!iframeIdRef.current) {
        iframeIdRef.current = nanoid()
      }
      setLoadNow(true)
    }
  }, [])

  const { ref: setInViewEmbeddedContentContainerRef } = useInView({
    rootMargin: '100% 0px 100% 0px',
    onChange: doLoadNow,
    triggerOnce: true,
  })

  const onIframeTrackEventReceived = useCallback<
    TrackingFnType<{ eventName: string; eventData: Record<string, unknown> }>
  >(
    ({ extraData }) => ({
      event: extraData.eventName,
      ...extraData.eventData,
    }),
    []
  )

  const onTrackEventReceived = useTracking(onIframeTrackEventReceived)

  const embeddedContentContainerRef = useRef<HTMLDivElement>()
  const setEmbeddedContentContainerRef = useCallback<
    (node?: Element | null | undefined) => void
  >(
    (node) => {
      if (node) {
        embeddedContentContainerRef.current = node as HTMLDivElement
        setInViewEmbeddedContentContainerRef(node)
      }
    },
    [setInViewEmbeddedContentContainerRef]
  )

  const { login } = useAuthentication()

  const sendUserData = useCallback(() => {
    if (withSSO && url) {
      // Only send the user data down to allowed origins
      // defined in the config by us
      const embeddedOrigin = new URL(url).origin
      if (
        (allowedOriginsToReceivePostMessage as unknown as string[]).includes(
          embeddedOrigin
        )
      ) {
        iframeRef?.current?.contentWindow?.postMessage({ user }, embeddedOrigin)
      }
    }
  }, [url, user, withSSO])

  const onHandlePostMessage = useCallback(
    (event: MessageEvent<PostMessage>) => {
      const { origin, data } = event

      if (url && !url.startsWith(origin)) {
        return
      }

      if (!data) {
        return
      }

      const { iframeId: hostedIFrameId } = data

      if (![iframeIdRef.current, url].includes(hostedIFrameId)) {
        return
      }

      const { type, blick_event_type } = data

      if (blick_event_type) {
        if (blick_event_type === 'iframeReady') {
          sendUserData()
          return
        }

        if (blick_event_type === 'TRACK') {
          const { name: eventName, data: eventData } = data
          onTrackEventReceived({ eventName, eventData })
          return
        }

        if (blick_event_type === 'resize') {
          const { height } = data

          const currentIFrameHeight =
            embeddedContentContainerRef.current?.clientHeight
          if (
            !!embeddedContentContainerRef.current &&
            !isNaN(height) &&
            height !== currentIFrameHeight
          ) {
            embeddedContentContainerRef.current.style.height = `${height}px`
          }
          return
        }

        if (blick_event_type === 'navigateTo') {
          router.push(data.href)
          return
        }

        if (blick_event_type === 'IN_WEBVIEW_NAVIGATION') {
          window.scrollTo(0, 0)
          return
        }

        if (blick_event_type === 'CMP_ENABLE_ALL_CATEGORIES') {
          window.OneTrust?.AllowAll()
          return
        }

        if (blick_event_type === 'CMP_SHOW_MORE_INFO') {
          window.OneTrust?.ToggleInfoDisplay()
          return
        }

        if (blick_event_type === 'NAVIGATE') {
          router.push(data.href)
          return
        }

        if (blick_event_type === 'LOGIN') {
          login({
            loginCase: data.loginCase ?? 'email_only',
            prefix: data.source ?? 'IFR',
          })
          return
        }
      } else if (type) {
        //! This has to map blick_event_type, due to migration reasons.
        //! It should be removed at the end.
        if (type === 'iframeReady') {
          sendUserData()
          return
        }

        if (type === 'TRACK') {
          const { name: eventName, data: eventData } = data
          onTrackEventReceived({ eventName, eventData })
          return
        }

        if (type === 'resize') {
          const { height } = data

          const currentIFrameHeight =
            embeddedContentContainerRef.current?.clientHeight
          if (
            !!embeddedContentContainerRef.current &&
            !isNaN(height) &&
            height !== currentIFrameHeight
          ) {
            embeddedContentContainerRef.current.style.height = `${height}px`
          }
          return
        }

        if (type === 'navigateTo') {
          router.push((data as NavigateToMessage).href)
          return
        }

        if (type === 'IN_WEBVIEW_NAVIGATION') {
          window.scrollTo(0, 0)
          return
        }

        if (type === 'CMP_ENABLE_ALL_CATEGORIES') {
          window.OneTrust?.AllowAll()
          return
        }

        if (type === 'CMP_SHOW_MORE_INFO') {
          window.OneTrust?.ToggleInfoDisplay()
          return
        }

        if (type === 'NAVIGATE') {
          router.push((data as NavigateMessage).href)
          return
        }

        if (type === 'LOGIN') {
          login({
            loginCase: (data as LoginMessage).loginCase ?? 'email_only',
            prefix: (data as LoginMessage).source ?? 'IFR',
          })
          return
        }
      } else {
        // Certain embeds can trigger a login action through their post message
        // Here we check if the action was true and if it was sent by the actual component
        const { loginAction, ssoTrackingSource } = data
        if (loginAction) {
          login({
            loginCase: 'email_only',
            prefix: ssoTrackingSource ?? 'IFR',
          })
        }
        return
      }
    },
    [url, sendUserData, onTrackEventReceived, router, login]
  )

  useEffect(() => {
    sendUserData()
  }, [sendUserData])

  useEffect(() => {
    window.addEventListener('message', onHandlePostMessage)

    return () => {
      window.removeEventListener('message', onHandlePostMessage)
    }
  }, [onHandlePostMessage])

  const trackingOnImpression = useCallback<TrackingFnType>(
    () => ({
      event: 'iframe_banner',
      eventCategory: 'iframeBanner',
      eventLabel: `${contentId}:${stripHtml(title)}`,
      eventAction: 'impression',
      targetUrl: url,
    }),
    [contentId, title, url]
  )

  const handleImpressionTrack = useTracking(trackingOnImpression)

  const ref = useRefDelegate(
    setIframeRef,
    useViewportTracking({
      onImpression: handleImpressionTrack,
      track: trackImpression,
    })
  )

  if (disableThirdPartyScripts) {
    return null
  }

  if (iframeError) {
    return null
  }

  return (
    <StyledEmbeddedContentContainer
      {...(url?.endsWith('/quizwidget') ? { id: 'quizwidget' } : {})}
      {...(className ? { className } : {})}
      maxHeight={maxHeight}
      maxHeightMobile={maxHeightMobile}
      ref={setEmbeddedContentContainerRef}>
      {loadNow && (
        <StyledIFrame
          id={iframeIdRef.current}
          ref={ref}
          src={url}
          title="Embedded Content"
          loading="eager"
          scrolling={scrollingEnabled ? 'yes' : 'no'}
          allowFullScreen
          data-chromatic="ignore"
        />
      )}
    </StyledEmbeddedContentContainer>
  )
}

const CMPAwareEmbeddedContent: FunctionComponent<
  CMPAwareEmbeddedContentProps
> = ({ bypassCMP, ...restOfProps }) => {
  let autoWhitelistUrl = false

  try {
    const { url } = restOfProps

    if (url) {
      const isBlickDomain = ['blick.ch'].some((domain) =>
        new URL(url as string).hostname.endsWith(domain)
      )

      const isStaticPage = ['/assets/dist/static'].some((pathSegment) =>
        new URL(url as string).pathname.includes(pathSegment)
      )

      autoWhitelistUrl = isBlickDomain && !isStaticPage
    }
  } catch (err) {
    // Do nothing
  }

  const allCategoriesEnabled = useCMPCookieCategories(
    'allCategoriesEnabledChanged'
  )

  return autoWhitelistUrl || bypassCMP || allCategoriesEnabled ? (
    <EmbeddedContent {...restOfProps} />
  ) : (
    <CMPPlaceholder />
  )
}

const MemoizedCMPAwareEmbeddedContent = memo(CMPAwareEmbeddedContent)

export { StyledEmbeddedContentContainer }

export default MemoizedCMPAwareEmbeddedContent
