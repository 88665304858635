import { FunctionComponent, useCallback, useMemo, useState } from 'react'
import styled, { css, useTheme } from 'styled-components'
import config from '@config'

import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import SummaryContainer from '@components/Summary/SummaryContainer'

import SecondaryButton from '@components/Buttons/Secondary'
import useTracking from '@hooks/useTracking'
import useViewportTracking from '@hooks/useViewportTracking'

import useABTestValue from '@hooks/useABTestValue'
import { mobileCSS } from '@measures/responsive'
import {
  trackQuestionSummaryClickHandler,
  trackQuestionSummaryImpression,
} from './utils'
import { QuestionContainer, QuestionMessage } from './Question'
import QuestionSummaryRequestView from './ArticleQuestionRequestView'
import {
  SkeletonSeed,
  SkeletonView,
} from '@components/Summary/SummaryLoadingSkeleton'
import { ErrorView } from '@components/Summary/SummaryError'
import SvgIcon from '@components/SvgIcon'
import translate from '@i18n'
import QuestionSummaryResultView from './ArticleQuestionResultView'
import SummaryDisclaimer from '@components/Summary/SummaryDisclaimer'
import { useQuery } from '@tanstack/react-query'
import { getAnswers, getQuestions } from '@utils/chatbot'
import type { TQuestionData } from '@utils/chatbot/types'
import { WidgetId } from '@utils/chatbot/types'
import type { QuestionSummaryProps } from './types'
import usePageMetadata from '@hooks/usePageMetadata'
import useModalOverlayPortal from '@hooks/useModalOverlayPortal'
import UserSnapInlineForm from '@components/UserSnap/InlineForm'

const {
  questionWidget: { moreInfoLink, userSnapFeedbackKey },
} = config

const IconPullRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: right;
  width: 100%;
  cursor: pointer;
`
const SummaryWrapper = styled.div`
  width: 100%;
  min-height: 211.19px;

  ${mobileCSS(css`
    min-height: 254.38px;
  `)}
`

const StyledSecondaryButton = styled(SecondaryButton)`
  ${({
    theme: {
      color: {
        primary: { primary01 },
      },
      typography: {
        subheads: {
          small1: { bundledCSS: smallBodyCopy },
        },
      },
      spacing: { spacing4, spacing8, spacing16 },
    },
  }) => {
    return css`
      ${smallBodyCopy}
      color: ${primary01};
      text-transform: none;
      width: fit-content;
      gap: ${spacing8};
      padding: ${spacing4} ${spacing16};
      background-color: transparent;
      border: 1px solid ${primary01};
      &:hover {
        background-color: transparent;
      }
    `
  }}
`

const QuestionSummary: FunctionComponent<QuestionSummaryProps> = ({
  title = '',
  questions,
  question: initialQuestion,
  links,
  content,
}) => {
  const articleId = usePageMetadata().id ?? ''
  const theme = useTheme()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { portalNode, enterModalOverlay, exitModalOverlay, InPortal } =
    useModalOverlayPortal()

  const isABTestEnabled = useABTestValue('showQuestionSummary') === 'show'
  const trackWidgetImpression = useTracking(trackQuestionSummaryImpression)
  const handleTrackingOnClick = useTracking(trackQuestionSummaryClickHandler)
  const viewportRef = useViewportTracking({
    track: isABTestEnabled,
    onImpression: trackWidgetImpression,
  })

  const [question, setQuestion] = useState<TQuestionData | undefined>(
    initialQuestion
  )

  const {
    data: quetionsData,
    isLoading: questionsIsLoading,
    isError: questionsIsError,
    refetch: questionsRefetch,
  } = useQuery({
    queryKey: ['questionSummary.questions', articleId],
    queryFn: async () => {
      const data = await getQuestions({
        widgetId: WidgetId.ArticleQuestion,
        context: articleId,
      })()

      return data.questions
    },
    initialData: questions ? questions : undefined,
  })

  const {
    data: answersData,
    error: answersIsError,
    isLoading: answersIsLoading,
    isPending: answersIsPending,
    refetch: refetchAnswers,
  } = useQuery({
    queryKey: ['questionSummary.answer', articleId, question?.id],
    enabled: !!question?.id, // only fetch if question is set and no data is cached
    queryFn: async () => {
      const data = await getAnswers({
        widgetId: WidgetId.ArticleQuestion,
        context: articleId,
        questionId: question?.id!,
      })()

      // return only the links and content
      return { links: data.links, content: data.content }
    },
    initialData: links && content ? { links, content } : undefined,
  })

  const cancelCallback = useCallback(() => {
    setQuestion(undefined)

    if (!questionsIsLoading && questionsIsError) {
      // refetch questions if there was an error
      questionsRefetch()
    }
  }, [setQuestion, questionsIsLoading, questionsIsError, questionsRefetch])

  const retryCallback = useCallback(() => {
    if (questionsIsError) {
      questionsRefetch()
    } else if (answersIsError) {
      refetchAnswers()
    }
  }, [refetchAnswers, answersIsError, questionsIsError, questionsRefetch])

  const isLoading = questionsIsLoading || answersIsLoading
  const isError = questionsIsError || answersIsError
  const isSummaryLoaded = quetionsData && !question && !isError // only show summary if no question is selected

  const isAnswerLoaded =
    question && answersData && !answersIsPending && !answersIsError

  const disclaimer = useMemo(
    () => ({
      title: translate('sectionSummary.disclaimerText'),
      links: [
        {
          label: translate('sectionSummary.disclaimerMoreInfo'),
          link: moreInfoLink,
          target: '_blank',
          onClickHandler: (label: string) => handleTrackingOnClick({ label }),
        },
        {
          label: translate('sectionSummary.disclaimerFeedback'),
          target: '_blank',
          onClickHandler: (label: string) => {
            event?.preventDefault()
            handleTrackingOnClick({ label })
            setIsModalVisible(true)
            enterModalOverlay()
          },
        },
      ],
    }),
    [handleTrackingOnClick, enterModalOverlay, setIsModalVisible]
  )

  if (!isABTestEnabled) {
    return null
  }

  return (
    <SummaryWrapper id="question-widget">
      {isABTestEnabled && (
        <SummaryContainer ref={viewportRef}>
          {portalNode && isModalVisible && (
            <InPortal node={portalNode}>
              <UserSnapInlineForm
                setIsVisible={(isVisible) => {
                  if (!isVisible) {
                    exitModalOverlay()
                  }
                  enterModalOverlay()
                }}
                projectApiKey={userSnapFeedbackKey}
              />
            </InPortal>
          )}

          {isSummaryLoaded && (
            <QuestionSummaryRequestView title={title}>
              {quetionsData.length ? (
                <QuestionContainer>
                  {quetionsData.map((data) => (
                    <QuestionMessage
                      onClick={() => {
                        setQuestion(data)
                        handleTrackingOnClick({ label: data.text })
                      }}
                      key={data.id}>
                      {data.text}
                    </QuestionMessage>
                  ))}
                </QuestionContainer>
              ) : null}
            </QuestionSummaryRequestView>
          )}

          {isAnswerLoaded && (
            <QuestionSummaryResultView
              articleId={articleId}
              title={translate('summary.result.title')}
              question={question}
              links={answersData.links}
              content={answersData.content}
              onCloseHandler={cancelCallback}>
              {disclaimer && <SummaryDisclaimer {...disclaimer} />}
            </QuestionSummaryResultView>
          )}

          {isLoading && (
            <QuestionSummaryRequestView title={title}>
              <SkeletonView
                seed={[{ type: SkeletonSeed.ParagraphNatural, multiplier: 7 }]}
              />
            </QuestionSummaryRequestView>
          )}

          {isError && !isLoading && (
            <ErrorView>
              <IconPullRight onClick={cancelCallback}>
                <SvgIcon
                  iconName="xmark-large"
                  size={24}
                  color={theme.color.primary.primary01}
                />
              </IconPullRight>
              {translate('sectionSummary.request.error')}

              <StyledSecondaryButton
                type="button"
                size="large"
                onClick={retryCallback}>
                {translate('sectionSummary.request.error.primaryButton')}
                <SvgIcon
                  iconName="arrows-rotate"
                  size={16}
                  color={theme.color.primary.primary01}
                />
              </StyledSecondaryButton>

              {disclaimer && <SummaryDisclaimer links={disclaimer.links} />}
            </ErrorView>
          )}
        </SummaryContainer>
      )}
    </SummaryWrapper>
  )
}

const widget = {
  kind: ['article-questions'],
  component: QuestionSummary,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
