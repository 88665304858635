import PrimaryButton from '@components/Buttons/Primary'
import { FunctionComponent, useCallback, useMemo, useState } from 'react'
import styled, { css, useTheme } from 'styled-components'
import LinkToFaqs from './LinkToFaqs'
import { TextAndButtonContainerProps } from './types'
import Spinner from './Loading/Spinner'
import useBlickPlusSkeleton from '@hooks/useBlickPlusSkeleton'
import translate from '@i18n'
import { copyToClipboard } from '@components/Social/utils'
import Text from '@components/PlusLinkShare/Text'

const CopyButton = styled(PrimaryButton)`
  ${({
    theme: {
      typography: {
        subheads: {
          small1: { bundledCSS: subheadingSmall1CSS },
        },
      },
      color: {
        primary: { blickRed },
        secondary: { darkRed },
        tertiary: { grey200, grey400 },
      },
    },
    isDisabled,
  }) => css`
    ${subheadingSmall1CSS};
    margin-top: 0;
    text-transform: none;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: ${blickRed};
    @media (hover: hover) {
      &:hover:not([disabled]) {
        background-color: ${darkRed};
      }
    }
    ${isDisabled &&
    css`
      background-color: ${grey200};
      color: ${grey400};
    `}
  `}
`

const TextAndButtonContainer: FunctionComponent<
  TextAndButtonContainerProps
> = ({
  onGenerateButtonClick,
  linkToCopy,
  updatedAvailableShares,
  isLinkLoading,
  isError,
  availableShares,
}) => {
  const theme = useTheme()
  const { plusSharingLinkGenerationAmount } = useBlickPlusSkeleton()
  const [isLinkCopied, setIsLinkCopied] = useState<boolean>(false)

  const hasRunOutOfShares = useMemo<boolean>(
    () => availableShares === 0,
    [availableShares]
  )

  const hasRunOutOfSharesText = translate(
    'blickPlus.linkSharing.hasRunOutOfSharesText'
  )

  const availableSharesText = useMemo<string>(
    () =>
      translate('blickPlus.linkSharing.availableSharesText', {
        linkAmount: `${plusSharingLinkGenerationAmount}`,
      }),

    [plusSharingLinkGenerationAmount]
  )

  const onCopyButtonClick = useCallback(() => {
    if (linkToCopy) {
      copyToClipboard(linkToCopy).then(() => {
        setIsLinkCopied(true)
      })
    }
  }, [linkToCopy])

  const buttonContent = useMemo(() => {
    if (isLinkLoading) {
      return <Spinner size={24} color={theme.color.primary.primary02} />
    }
    if (typeof updatedAvailableShares !== 'number') {
      return translate('blickPlus.linkSharing.copyButton.generate')
    }
    if (isLinkCopied) {
      return translate('blickPlus.linkSharing.copyButton.copied')
    }
    return translate('blickPlus.linkSharing.copyButton.copy')
  }, [
    updatedAvailableShares,
    theme.color.primary.primary02,
    isLinkLoading,
    isLinkCopied,
  ])

  return (
    <>
      <Text>
        {hasRunOutOfShares ? hasRunOutOfSharesText : availableSharesText}
      </Text>
      <LinkToFaqs />
      <CopyButton
        type="button"
        size="small"
        onClick={!!linkToCopy ? onCopyButtonClick : onGenerateButtonClick}
        isDisabled={
          hasRunOutOfShares || isError || isLinkLoading || isLinkCopied
        }>
        {buttonContent}
      </CopyButton>
    </>
  )
}

export default TextAndButtonContainer
