export type TQuestionData = { id: string; text: string; cached: boolean }

export type TAnswerData = { text: string; link: string; category?: string }

export type TSourceData = {
  url: string
  title: string
  isPlus: boolean
}

export type TLinkData = Record<string, TSourceData>

export type TQuestionResponse = {
  spanId: string
  traceId: string
  version: string
  questions: TQuestionData[]
}
export type TAnswerResponse = {
  id: string
  spanId: string
  traceId: string
  version: string
  content: TAnswerData[]
  links: TLinkData
}

export enum WidgetId {
  ArticleQuestion = 'articleQuestion',
  SectionSummary = 'dailyBriefing',
}
