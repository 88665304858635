import { css } from 'styled-components'
import config from '@config'
import { captureException, captureExceptionWithUser } from '@utils/sentry'
import { QueryClient } from '@tanstack/react-query'
import {
  PianoCheckoutParams,
  PianoCustomCheckoutParams,
  SubscriptionStatusForPianoTemplates,
} from './types'
import { SubscriptionTransformedData } from '@components/Auth/types'
import { isDateWithinDaysRange } from '@utils/date'
import { getCookieValue } from '@utils/cookie'
import { NextRouter } from 'next/router'

interface PianoTokenResponse {
  userRefToken?: string
}

interface PianoCustomVariables {
  [key: string]: string
}

const {
  abTest: { windowKey },
  piano: {
    tokenUrl,
    experienceCookieName,
    freeToReadArticleCookieName,
    blickCookiesDomain,
    modalZIndex,
  },
  subscriptions: {
    daysBeforeSubscriptionExpires,
    internalAccessId,
    enableAutoRenewalUrl,
    footerBannerClassname,
  },
} = config

const setCustomVariables = (customVariables: PianoCustomVariables) => {
  for (const key in customVariables) {
    window.tp?.push(['setCustomVariable', key, customVariables[key]])
  }
}

const setTags = (flags: Record<string, boolean | undefined>) => {
  const pianoTags = Object.keys(flags).reduce((acc, flagName) => {
    if (flags[flagName]) {
      acc.push(flagName)
    }
    return acc
  }, [] as string[])
  window?.tp?.push?.(['setTags', pianoTags])
}

const setModalZIndexes = () => {
  window?.tp?.push?.([
    'setZIndexes',
    {
      backdrop: modalZIndex - 1,
      modal: modalZIndex,
      close: modalZIndex + 1,
    },
  ])
}

const doParseStringifiedData = (stringifiedData: string) => {
  let parsed = null
  try {
    parsed = JSON.parse(stringifiedData)
  } catch (e: any) {
    parsed = false
    captureException(e)
  }
  return parsed
}

const setExperienceCookie = (params: any): void => {
  document.cookie = `${experienceCookieName}=${encodeURIComponent(
    JSON.stringify(params)
  )}; expires=${(
    new Date(new Date().getTime() + 60 * 60 * 1000) as any
  ).toUTCString()}; path=/; domain=${blickCookiesDomain}`
}

const resetExperienceCookie = (): void => {
  document.cookie = `${experienceCookieName}=null; expires=-1; path=/; domain=${blickCookiesDomain}`
}

// make https request to our own service which generates a JWT token
const setJWTToken = (cb: (userToken: string | undefined) => void): void => {
  fetch(tokenUrl, {
    credentials: 'include',
  })
    .then((response) => response.json())
    .then((data: PianoTokenResponse) => {
      // In the response, it's still called userRefToken to avoid making changes to native platforms, but it's a JWT
      window?.tp?.push?.(['setExternalJWT', data.userRefToken])
      cb(data.userRefToken)
    })
}

const logPiano = (
  {
    queryClient,
    method,
  }: {
    queryClient: QueryClient
    method?: 'info' | 'log' | 'warn' | 'error'
  },
  ...args: any
): void => {
  const showPianoLogs =
    queryClient.getQueryData<'show'>([windowKey, 'showPianoRelatedLogs']) ===
    'show'

  if (showPianoLogs) {
    console[method ?? 'log']('PIANO: ', ...args)
  }
}

const globalPianoStyles = css`
  .tp-iframe-wrapper.tp-iframe-wrapper {
    box-shadow: none;
    margin-top: 0;
    margin-bottom: 0;
  }
`

const isGoogleBot = (url: string): boolean => {
  if (!url) {
    return false
  }
  const botNames = ['googlebot', 'google-inspectiontool']
  return botNames.some((botName) => url.toLowerCase().includes(botName))
}

const getSubscriptionStatusForPianoTemplates = (
  queryClient: QueryClient
): SubscriptionStatusForPianoTemplates => {
  const subscriptionData =
    queryClient.getQueryData<SubscriptionTransformedData>(['subscription-data'])

  if (subscriptionData?.user_status === 'subscribed') {
    return subscriptionData?.product_id === internalAccessId
      ? 'internalAccess'
      : 'localPlatform'
  }
  return 'notSubscribed'
}

const getSubscriptionExpiringSoonForPianoTemplates = (
  queryClient: QueryClient
) => {
  const subscriptionData =
    queryClient.getQueryData<SubscriptionTransformedData>(['subscription-data'])

  if (subscriptionData?.subscription_valid_until) {
    return isDateWithinDaysRange(
      subscriptionData.subscription_valid_until,
      daysBeforeSubscriptionExpires
    ).toString()
  }

  return 'false'
}

const getTrackingCheckoutParams = (queryClient: QueryClient) =>
  queryClient.getQueryData<PianoCheckoutParams>(['pianoCheckoutParams'])

const setTrackingCheckoutParams = (
  queryClient: QueryClient,
  params: PianoCheckoutParams | null
) => {
  queryClient.setQueryData(['pianoCheckoutParams'], params)
  queryClient.invalidateQueries({
    queryKey: ['pianoCheckoutParams'],
    exact: true,
  })
}

const getTrackingTemplateParams = (experienceActionId?: string) => {
  const isOfferOrPlusTemplate = (keyword: string, moduleName?: string) => {
    return moduleName
      ? moduleName.toLowerCase().includes(keyword)
      : !!moduleName
  }

  return {
    piano_template_has_offer: isOfferOrPlusTemplate(
      'offer',
      experienceActionId
    ),
    piano_template_is_plus: isOfferOrPlusTemplate('plus', experienceActionId),
  }
}

const enableSubscriptionAutoRenewal = async (
  queryClient: QueryClient,
  pianoParams: PianoCustomCheckoutParams
) => {
  const subscriptionData =
    queryClient.getQueryData<SubscriptionTransformedData>(['subscription-data'])

  // The auto renewal endpoint needs a term id, to be able to distinguish for which subscription
  // the auto renewal needs to be enabled.
  if (subscriptionData?.product_id) {
    const response = await fetch(enableAutoRenewalUrl, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        termId: subscriptionData.product_id,
      }),
    })

    if (response.ok) {
      // Setting a custom variable, so that we can show a success message inside of the Piano template
      setCustomVariables({
        autoRenewalEnableSuccess: 'true',
      })
    } else {
      captureExceptionWithUser(
        `[PLUS SERVICE ERROR] POST enable autorenewal returned an error: ${response.statusText}`,
        queryClient
      )

      // Setting a custom variable, so that we can show an error message inside of the Piano template
      setCustomVariables({
        autoRenewalEnableSuccess: 'false',
      })
    }

    // We have to trigger once again the template show function from Piano, so that it will pick up
    // the new custom variables that we set above. This is currently the only way, as Piano doesn't offer
    // a way to replace the custom variables without reloading the template
    window?.tp?.template.show({
      templateId: pianoParams.templateId,
      displayMode: 'inline',
      containerSelector: `.${footerBannerClassname}`,
    })
  }
}

const setIsArticleFreeToReadCookie = (router: NextRouter, url?: string) => {
  if (!url) return

  // Store the URL in a cookie to compare against on page reload
  document.cookie = `${freeToReadArticleCookieName}=${url}; expires=${(
    new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000) as any
  ).toUTCString()}; path=/; domain=${blickCookiesDomain}`

  // Reload the page
  router.reload()
}

const isArticleFreeToRead = (articleId?: string) => {
  const url = getCookieValue(freeToReadArticleCookieName) as string

  if (!url || !articleId) return false
  // Return whether the url contains the articleId
  return url.includes(articleId)
}

const paywallByPassReason = (articleId?: string) =>
  // Currently the only reason to read an article for free is the microtransaction test
  // This will be extended in the future
  isArticleFreeToRead(articleId) ? 'microtransaction' : 'none'

export {
  doParseStringifiedData,
  resetExperienceCookie,
  setCustomVariables,
  setExperienceCookie,
  setTags,
  setModalZIndexes,
  setJWTToken,
  logPiano,
  globalPianoStyles,
  isGoogleBot,
  getSubscriptionStatusForPianoTemplates,
  getSubscriptionExpiringSoonForPianoTemplates,
  getTrackingCheckoutParams,
  setTrackingCheckoutParams,
  getTrackingTemplateParams,
  enableSubscriptionAutoRenewal,
  setIsArticleFreeToReadCookie,
  isArticleFreeToRead,
  paywallByPassReason,
}
