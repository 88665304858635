import { FunctionComponent, useCallback, useRef, useState } from 'react'
import styled, { css, useTheme } from 'styled-components'

import { desktopCSS, tabletCSS, mobileCSS } from '@measures/responsive'
import useViewportType from '@hooks/useViewport/useViewportType'
import { VideoRecommendedItem } from '@hooks/useRecommendations'
import { getPlayerControlbar, getJWPlayer } from '@utils/videoPlayer'
import Swiper, { SwiperClass, ArrowsVisibilityType } from '@components/Swiper'
import SectionHeader from '@components/SectionHeader'
import NavigationArrows from '@components/Swiper/NavigationArrows'
import InvertedColorsContext from '@contexts/invertedColors'

import CloseButton, { CloseButtonProps } from './CloseButton'
import Portal from './Portal'
import VideoRecommendationsItem, {
  VideoRecommendationsItemProps,
} from './VideoRecommendationsItem'

interface VideoRecommendationsProps {
  widgetId: string
  data: VideoRecommendedItem[]
  hidden?: boolean
  isFullScreen?: boolean
  onClickClose: CloseButtonProps['onClick']
  onClickItem: VideoRecommendationsItemProps['onClick']
  onItemImpression: VideoRecommendationsItemProps['onImpression']
}

const BackgroundOverlay = styled.div<Pick<VideoRecommendationsProps, 'hidden'>>`
  ${({
    theme: {
      color: {
        transparency: { primary01Color80: primary01Color80Color },
      },
      animation: {
        transition: { fast: animationTransitionFast },
      },
    },
    hidden,
  }) => css`
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    background: ${primary01Color80Color};

    opacity: ${hidden ? 0 : 1};
    transform: translate3d(0, 0, 0);
    transition: ${animationTransitionFast} opacity ease-in-out;

    ${hidden &&
    css`
      transform: translate3d(0, 100%, 0);
      transition:
        ${animationTransitionFast} transform ease-in-out
          ${animationTransitionFast},
        ${animationTransitionFast} opacity ease-in-out;
    `};
  `}
`

const Wrapper = styled.div<
  Pick<VideoRecommendationsProps, 'hidden' | 'isFullScreen'> & {
    controlbarHeight?: number
  }
>`
  ${({
    theme: {
      animation: {
        transition: {
          fast: animationTransitionFast,
          default: animationTransitionDefault,
        },
      },
    },
    hidden,
    controlbarHeight,
    isFullScreen,
  }) => css`
    display: flex;
    z-index: 6;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: calc(${controlbarHeight}px + 16px);
    transform: translate3d(0, 0, 0);

    ${hidden &&
    css`
      transform: translate3d(0, calc(100% + ${controlbarHeight}px + 16px), 0);
      transition: ${animationTransitionFast} transform ease-in-out
        ${animationTransitionDefault};
    `};

    ${isFullScreen &&
    css`
      bottom: 0;
      justify-content: center;
      align-items: center;

      ${desktopCSS(css`
        bottom: calc(${controlbarHeight}px + 24px);
      `)}
    `};
  `}
`

const InnerWrapper = styled.div<
  Pick<VideoRecommendationsProps, 'hidden' | 'isFullScreen'>
>`
  ${({
    theme: {
      animation: {
        transition: { fast: animationTransitionFast },
      },
    },
    hidden,
    isFullScreen,
  }) => css`
    opacity: ${hidden ? 0 : 1};
    transition: opacity ease-in-out ${animationTransitionFast};
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    ${isFullScreen &&
    css`
      height: auto;

      ${desktopCSS(css`
        height: 100%;
      `)}
    `};
  `}
`

const SwiperWrapper = styled.div<
  Pick<VideoRecommendationsProps, 'isFullScreen'>
>`
  ${({
    theme: {
      spacing: { spacing8, spacing16 },
    },
    isFullScreen,
  }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${spacing8};

    padding: 0 ${spacing16};

    ${isFullScreen &&
    css`
      ${desktopCSS(css`
        row-gap: ${spacing16};
      `)}
    `};
  `}
`

const StyledNavigationArrows = styled(NavigationArrows)<{ itemsCount: number }>`
  ${({ itemsCount }) => css`
    ${itemsCount <= 1 &&
    mobileCSS(css`
      display: none;
    `)};
    ${itemsCount <= 2 &&
    tabletCSS(css`
      display: none;
    `)};
    ${itemsCount <= 3 &&
    desktopCSS(css`
      display: none;
    `)}
  `}
`

const StyledSwiper = styled(Swiper)`
  ${({
    theme: {
      spacing: { spacing16, spacing24 },
    },
  }) => css`
    width: auto;
    margin-left: -${spacing16};
    margin-right: -${spacing16};

    > .swiper-items-container {
      grid-gap: ${spacing16};

      > * {
        scroll-margin-left: ${spacing16};
      }

      > *:first-child {
        margin-left: ${spacing16};
      }

      > *:last-child {
        margin-right: ${spacing16};
        scroll-margin-right: ${spacing16};
      }

      ${desktopCSS(css`
        grid-gap: ${spacing24};
      `)}
    }
  `}
`

const SwiperSlide = styled.div<Pick<VideoRecommendationsProps, 'isFullScreen'>>`
  ${({
    theme: {
      color: {
        primary: { primary02: primary02Color },
      },
    },
    isFullScreen,
  }) => css`
    border: 1px solid ${primary02Color};
    width: 200px;

    ${isFullScreen &&
    css`
      ${desktopCSS(css`
        width: 358px;
      `)}
    `};
  `}
`

export const VideoRecommendations: FunctionComponent<
  VideoRecommendationsProps
> = ({
  hidden = true,
  widgetId,
  data,
  onClickClose,
  onClickItem,
  onItemImpression,
  isFullScreen,
}) => {
  const theme = useTheme()

  const viewportType = useViewportType()
  const isDesktop = viewportType === 'desktop'

  const jwPlayer = getJWPlayer(widgetId)
  const controlbarHeight =
    (jwPlayer && getPlayerControlbar(jwPlayer)?.clientHeight) ?? 0

  const swiperRef = useRef<SwiperClass | null>(null)
  const [arrowsVisibility, setArrowsVisibility] =
    useState<ArrowsVisibilityType>()

  const onInit = useCallback(
    (swiper: any) => {
      if (swiperRef) {
        swiperRef.current = swiper
      }
    },
    [swiperRef]
  )

  const onArrowsVisibility = useCallback((arrow: ArrowsVisibilityType) => {
    setArrowsVisibility(arrow)
  }, [])

  return (
    <>
      <Wrapper
        hidden={hidden}
        isFullScreen={isFullScreen}
        controlbarHeight={controlbarHeight}>
        <InnerWrapper hidden={hidden} isFullScreen={isFullScreen}>
          <CloseButton onClick={onClickClose} isFullScreen={isFullScreen} />
          <InvertedColorsContext.Provider value={true}>
            <SwiperWrapper isFullScreen={isFullScreen}>
              <SectionHeader
                title="Weitere Videos"
                accentColor={theme.color.primary.blickRed}
                isSubheader={true}>
                <StyledNavigationArrows
                  swiperRef={swiperRef}
                  arrowsVisibility={arrowsVisibility}
                  itemsCount={data?.length ?? 0}
                />
              </SectionHeader>
              <StyledSwiper
                initialSlide={0}
                spaceTopBottom={0}
                slidesPerGroup={isDesktop ? 3 : 1}
                onInit={onInit}
                onArrowsVisibility={onArrowsVisibility}
                slides={data?.map((item) => (
                  <SwiperSlide key={item.articleId} isFullScreen={isFullScreen}>
                    <VideoRecommendationsItem
                      {...item}
                      hidden={hidden}
                      isFullScreen={isFullScreen}
                      onClick={onClickItem}
                      onImpression={onItemImpression}
                    />
                  </SwiperSlide>
                ))}
              />
            </SwiperWrapper>
          </InvertedColorsContext.Provider>
        </InnerWrapper>
      </Wrapper>
      <BackgroundOverlay hidden={hidden} />
    </>
  )
}

const VideoRecommendationsPortal: FunctionComponent<
  VideoRecommendationsProps
> = (props) => {
  const parentNode = getJWPlayer(props.widgetId)?.getContainer() as HTMLElement

  return (
    <Portal parentNode={parentNode}>
      <VideoRecommendations {...props} />
    </Portal>
  )
}

export default VideoRecommendationsPortal
