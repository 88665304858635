import { FunctionComponent, useCallback, useRef, useState } from 'react'
import styled, { css, useTheme } from 'styled-components'

import { Link } from '@utils/cook/types'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { WidgetKind } from '@utils/registry'
import { TeaserStandardHorizontalAPIProps } from '@widgets/TeaserStandardHorizontal'

import { desktopCSS, mobileCSS, tabletCSS } from '@measures/responsive'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import useViewportTracking from '@hooks/useViewportTracking'
import { stripHtml } from '@hooks/useTracking/utils'
import useViewportType from '@hooks/useViewport/useViewportType'

import Swiper, { ArrowsVisibilityType, SwiperClass } from '@components/Swiper'
import NavigationArrows from '@components/Swiper/NavigationArrows'
import SectionHeader from '@components/SectionHeader'
import JSONRenderer from '@components/JSONRenderer'
import { printCSS } from '@utils/style'

type MoreAboutItem = TeaserStandardHorizontalAPIProps & {
  kind: WidgetKind
}

export interface MoreAboutWidgetAPIProps {
  accentColor: string
  link?: Link
  title: string
  items: MoreAboutItem[]
}

const MoreAboutWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing16, spacing24 },
    },
  }) => css`
    display: grid;
    grid-row-gap: ${spacing16};

    ${desktopCSS(css`
      grid-row-gap: ${spacing24};
    `)}
    ${printCSS(css`
      display: none;
    `)}
  `}
`

const StyledNavigationArrows = styled(NavigationArrows)<{ itemsCount: number }>`
  ${({ itemsCount }) => css`
    ${itemsCount <= 2 &&
    mobileCSS(css`
      display: none;
    `)}
    ${itemsCount <= 2 &&
    tabletCSS(css`
      display: none;
    `)}
    ${itemsCount <= 4 &&
    desktopCSS(css`
      display: none;
    `)}
  `}
`

const StyledSwiper = styled(Swiper)<{ itemsCount: number }>`
  ${({
    theme: {
      spacing: { spacing16, spacing24, spacing32 },
    },
    itemsCount,
  }) => css`
    & > .swiper-items-container {
      grid-gap: ${spacing16};
      grid-auto-flow: column;
      grid-template-rows: repeat(2, auto);
      grid-auto-columns: 100%;

      ${desktopCSS(css`
        grid-auto-columns: max-content;
        grid-template-rows: repeat(${itemsCount > 2 ? 2 : 1}, auto);

        grid-column-gap: ${spacing32};
        grid-row-gap: ${spacing24};
      `)}
    }
  `}
`

const SwiperSlide = styled.div`
  ${() => css`
    width: 100%;

    ${desktopCSS(css`
      width: 300px;
    `)}
  `}
`

const MoreAboutWidget: FunctionComponent<MoreAboutWidgetAPIProps> = ({
  title,
  items,
  accentColor,
  link,
}) => {
  const theme = useTheme()
  const viewportType = useViewportType()
  const isDesktop = viewportType === 'desktop'

  const swiperRef = useRef<SwiperClass | null>(null)
  const [arrowsVisibility, setArrowsVisibility] =
    useState<ArrowsVisibilityType>()

  const onInit = useCallback(
    (swiper: any) => {
      if (swiperRef) {
        swiperRef.current = swiper
      }
    },
    [swiperRef]
  )

  const onArrowsVisibility = useCallback((arrow: ArrowsVisibilityType) => {
    setArrowsVisibility(arrow)
  }, [])

  const onImpression = useCallback<TrackingFnType>(
    ({ pageMetadata: { title, id } }) => ({
      event: 'article_impression_more_about',
      eventCategory: 'article',
      eventLabel: `${id}:${stripHtml(title)}`,
      eventAction: 'impression_more_about',
    }),
    []
  )
  const onClick = useCallback<TrackingFnType<{ prevOrNext: 'prev' | 'next' }>>(
    ({ extraData: { prevOrNext } }) => ({
      event: 'arrowClick_mehrZumThema',
      eventCategory: 'related content',
      eventLabel: prevOrNext,
      eventAction: 'arrowClick_mehrZumThema',
    }),
    []
  )

  const trackedOnImpression = useTracking(onImpression)
  const trackedOnClick = useTracking(onClick)

  const viewportRef = useViewportTracking({
    track: true,
    onImpression: trackedOnImpression,
  })

  const onNavigationArrowsClick = useCallback(
    (prevOrNext: 'prev' | 'next') => {
      trackedOnClick({ prevOrNext })
    },
    [trackedOnClick]
  )

  if (!title) {
    return null
  }

  return (
    <MoreAboutWrapper ref={viewportRef}>
      <SectionHeader
        title={title}
        accentColor={accentColor ?? theme.color.primary.blickRed}
        link={link}
        isSubheader={true}>
        <StyledNavigationArrows
          swiperRef={swiperRef}
          arrowsVisibility={arrowsVisibility}
          itemsCount={items?.length ?? 0}
          onClick={onNavigationArrowsClick}
        />
      </SectionHeader>
      <StyledSwiper
        initialSlide={0}
        spaceTopBottom={0}
        slidesPerGroup={isDesktop ? 4 : 2}
        onInit={onInit}
        onArrowsVisibility={onArrowsVisibility}
        itemsCount={items?.length ?? 0}
        slides={items?.map((item, index) => (
          <SwiperSlide key={item.link.targetArticleId ?? index}>
            <JSONRenderer>{item}</JSONRenderer>
          </SwiperSlide>
        ))}
      />
    </MoreAboutWrapper>
  )
}

const widget = {
  kind: ['more-about'],
  component: MoreAboutWidget,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
