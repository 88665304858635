import { Date, Link, Image } from '@cook-types/cook'

export interface DateTime {
  date: string
  time: string
  dateOrTime: string
  sameDateTime: string | null
}

export type FormattedDateTime = DateTime | '' | {}

export interface HeadlineHeroImage extends Required<Image> {
  credit: { text: string; link: Link }
}

export type CommonHeadlineLeadAPIProps = {
  title: string
  catchword: string
  lead: string
  displayCommentsOnArticle: boolean
  publishedDate: Date
  updatedDate: Date
}

export type NormalHeadlineLeadAPIProps = CommonHeadlineLeadAPIProps

export type HerotellingHeadlineLeadAPIProps = CommonHeadlineLeadAPIProps & {
  storytellingImage: HeadlineHeroImage
}

export type HeadlineLeadAPIProps =
  | NormalHeadlineLeadAPIProps
  | HerotellingHeadlineLeadAPIProps

const isInHerotellingPageHeadlineLeadAPIProps = (
  _props: any,
  isInHerotellingPage: boolean
): _props is HerotellingHeadlineLeadAPIProps => isInHerotellingPage

export { isInHerotellingPageHeadlineLeadAPIProps }
