import config from '@config'
import { TrackingFnType } from '@hooks/useTracking'

const {
  chatbot: { baseUrl },
  sectionSummary: { answserUrl, questionsUrl },
} = config

export type TrackingClickType =
  | 'home_summary_click'
  | 'article_summary_click'
  | 'question_widget_click'

export const trackClickHandler: TrackingFnType<{
  event: TrackingClickType
  label?: string
  linkUrl?: string
}> = ({ extraData: { event, label, linkUrl } }) => ({
  event: event,
  ...(label && { button_label: label }),
  ...(linkUrl && { link_url: linkUrl }),
})

export const getAnswers = async ({ questionId }: { questionId: string }) => {
  const url = new URL(`${baseUrl}${answserUrl}`)
  url.searchParams.append('publication', 'blick')
  url.searchParams.append('questionId', questionId)
  url.searchParams.append('widgetId', 'dailyBriefing')
  url.searchParams.append('context', 'home')

  const response = await fetch(url, {
    method: 'GET',
  })

  if (response.status >= 400 && response.status < 600) {
    throw new Error(`Server responded with ${response.status}`)
  }

  return await response.json()
}

export const getQuestions = async () => {
  const url = new URL(`${baseUrl}${questionsUrl}`)
  url.searchParams.append('publication', 'blick')
  url.searchParams.append('widgetId', 'dailyBriefing')
  url.searchParams.append('context', 'home')

  const response = await fetch(url, {
    method: 'GET',
  })

  if (response.status >= 400 && response.status < 600) {
    throw new Error(`Server responded with ${response.status}`)
  }

  return await response.json()
}
