import React, { FunctionComponent, useRef } from 'react'
import styled, { css, useTheme } from 'styled-components'
import SvgIcon from '../SvgIcon'
import { mobileCSS } from '@measures/responsive'
import useTracking from '@hooks/useTracking'
import usePopoverPosition, {
  PopoverPositionType,
} from '@hooks/usePopoverPosition'
import {
  trackClickHandler,
  TrackingClickType,
} from '@widgets/SectionSummary/utils'
import useViewportType from '../../hooks/useViewport/useViewportType'
import { SwiperSlide } from './SummarySliderComponents'
import { TSourceData } from '@utils/chatbot/types'

interface SummaryPillProps {
  children: React.ReactNode
  popoverParentRef?: React.RefObject<HTMLElement>
  isActive?: boolean
  tooltipItem?: TSourceData
  trackingClickType: TrackingClickType
}

const Pill = styled.span<
  Pick<SummaryPillProps, 'isActive'> & { hasClickHandler?: boolean }
>`
  ${({
    theme: {
      color: {
        secondary: { darkRed },
        primary: { primary02 },
        tertiary: { grey600 },
      },
      typography: {
        labels: {
          label3: { bundledCSS: label3Labels },
        },
      },
    },
    isActive,
  }) => css`
    ${label3Labels}
    align-items: center;
    background-color: ${grey600};
    border-radius: 50%;
    color: ${primary02};
    display: inline-flex;
    height: 16px;
    justify-content: center;
    text-align: center;
    width: 16px;

    ${isActive && `background-color: ${darkRed};`}
  `}
`
const StyledPlusIcon = styled(SvgIcon)`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => {
    return css`
      vertical-align: text-bottom;
      margin-right: ${spacing8};
    `
  }}
`

const SummaryPillWrapper = styled.div`
  position: relative;
  display: inline-block;

  > a {
    display: none;
  }

  &:hover {
    > a {
      display: block;
    }
  }

  ${mobileCSS(css`
    &:hover {
      > a {
        display: none;
      }
    }
  `)}
`

const TooltipItemWrapper = styled(SwiperSlide)<{
  popoverPosition: PopoverPositionType
}>`
  ${({
    popoverPosition,
    theme: {
      spacing: { spacing8, spacing80 },
    },
  }) => css`
    position: absolute;
    bottom: 25px;
    right: -${spacing80};

    &:hover {
      span:last-child {
        background-color: transparent;
      }
    }

    ${popoverPosition === 'top-left' &&
    css`
      right: -${spacing8};
      left: auto;
    `}

    ${popoverPosition === 'top-right' &&
    css`
      left: -${spacing8};
      right: auto;
    `}
    ${popoverPosition === 'top-right' &&
    css`
      left: auto;
    `}
  `}
`

const SummaryPill: FunctionComponent<SummaryPillProps> = ({
  children,
  tooltipItem,
  isActive,
  trackingClickType,
  popoverParentRef,
}) => {
  const tooltipRef = useRef<HTMLAnchorElement>(null)
  const pillRef = useRef<HTMLAnchorElement>(null)
  const viewportType = useViewportType()
  const isMobile = viewportType !== 'desktop'
  const theme = useTheme()
  const popoverPosition = usePopoverPosition({
    popoverRef: tooltipRef,
    itemRef: pillRef,
    popoverParentRef: popoverParentRef,
  })

  const handleTrackingOnClick = useTracking(trackClickHandler)
  const url = tooltipItem?.url ? new URL(tooltipItem.url) : { pathname: '' }

  return (
    <SummaryPillWrapper>
      {tooltipItem && !isMobile && (
        <TooltipItemWrapper
          popoverPosition={popoverPosition}
          ref={tooltipRef}
          href={url.pathname}
          onClick={() => {
            handleTrackingOnClick({
              event: trackingClickType,
              label: 'article_source',
              linkUrl: url.pathname,
            })
          }}
          key={`source-item-${tooltipItem.url}-${children}`}>
          {tooltipItem.isPlus && (
            <StyledPlusIcon
              iconName="blick-plus-logo"
              size={19}
              color={theme.color.primary.primary01}
            />
          )}
          {tooltipItem.title}
        </TooltipItemWrapper>
      )}
      <Pill ref={pillRef} isActive={isActive}>
        {children}
      </Pill>
    </SummaryPillWrapper>
  )
}

export default SummaryPill
