import { FunctionComponent, useCallback } from 'react'
import styled, { css, useTheme } from 'styled-components'
import SvgIcon from '@components/SvgIcon'
import useIsDarkModeInSocialButton from '@hooks/useIsDarkModeInSocialButton'
import { printCSS } from '@utils/style'
import { useQueryClient } from '@tanstack/react-query'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import { stripHtml } from '@hooks/useTracking/utils'
import { scrollToCommentingWidget } from '@widgets/Commenting/utils'
import useCommentsNumber from '@hooks/useCommentsNumber'

type StyledButtonProps = {
  isDarkMode: boolean
}

const StyledButton = styled.button<StyledButtonProps>`
  ${({
    theme: {
      spacing: { spacing4, spacing16 },
      typography: {
        subheads: {
          medium1: { bundledCSS: mediumHeadingCSS },
        },
      },
      color: {
        primary: { primary02, blickRed },
        secondary: { darkRed },
        tertiary: { grey800, grey400 },
      },
    },
    isDarkMode,
  }) => {
    return css`
      padding: ${spacing4} ${spacing16};
      height: 40px;
      background-color: ${isDarkMode ? grey800 : 'transparent'};
      display: flex;
      font-style: normal;
      cursor: pointer;
      border-radius: 50px;
      border: ${isDarkMode ? 'none' : `1px solid ${blickRed}`};
      color: ${isDarkMode ? primary02 : blickRed};
      margin-left: auto;
      align-items: center;

      ${mediumHeadingCSS}
      &:hover {
        ${isDarkMode && `background-color: ${grey400}`};
        border: ${isDarkMode ? 'none' : `1px solid ${darkRed}`};
        color: ${isDarkMode ? primary02 : darkRed};

        svg {
          fill: ${isDarkMode ? primary02 : darkRed};
        }
      }

      ${printCSS(css`
        display: none;
      `)}
    `
  }}
`

const StyledSvgIconWrapper = styled.span`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => {
    return css`
      margin-right: ${spacing8};
      display: flex;
    `
  }}
`

const CommentButton: FunctionComponent = () => {
  const theme = useTheme()
  const isDarkMode = useIsDarkModeInSocialButton()
  const color = isDarkMode
    ? theme.color.primary.primary02
    : theme.color.primary.blickRed
  const queryClient = useQueryClient()
  const commentsNumber = useCommentsNumber()

  const articleClickShowCommentHandler = useCallback<TrackingFnType>(
    ({ pageMetadata }) => ({
      event: 'article_show_comments',
      eventCategory: 'article',
      eventAction: 'show_comments',
      eventLabel: `${pageMetadata.id}:${stripHtml(pageMetadata.title)}`,
    }),
    []
  )

  const handleArticleClickShowCommentTracking = useTracking(
    articleClickShowCommentHandler
  )

  const handleClick = useCallback(() => {
    handleArticleClickShowCommentTracking()
    scrollToCommentingWidget(queryClient)
  }, [handleArticleClickShowCommentTracking, queryClient])

  return (
    <StyledButton isDarkMode={isDarkMode} onClick={handleClick}>
      <StyledSvgIconWrapper>
        <SvgIcon iconName="message-lines" size={20} color={color} />
      </StyledSvgIconWrapper>
      {commentsNumber}
    </StyledButton>
  )
}

export default CommentButton
