import { FunctionComponent, useMemo, useState } from 'react'
import { QuestionSummaryProps } from './types'
import SummaryHeader from '@components/Summary/SummaryHeader'
import SvgIcon from '@components/SvgIcon'
import { useTheme, styled, css } from 'styled-components'
import { SummarySlider } from '@components/Summary/SummarySlider'
import translate from '@i18n'
import { SwiperSlide } from '@components/Summary/SummarySliderComponents'
import SummaryPill from '@components/Summary/SummaryPill'
import SummaryList from '@components/Summary/SummaryList'

const StyledQuestion = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
      typography: {
        bodycopy: {
          small1: { bundledCSS: small1 },
        },
      },
    },
  }) => css`
    ${small1}
    margin-bottom: ${spacing16};
  `}
`
const IconPullRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: right;
  width: 100%;
  cursor: pointer;
`

const StyledPlusIcon = styled(SvgIcon)`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => {
    return css`
      vertical-align: text-bottom;
      margin-right: ${spacing8};
    `
  }}
`

const QuestionSummaryResultView: FunctionComponent<
  Omit<QuestionSummaryProps, 'kind' | 'state'> & {
    onCloseHandler: () => void
    children?: React.ReactNode
  }
> = ({ title, content, links, question, children, onCloseHandler }) => {
  const theme = useTheme()
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined)
  const linksCount = useMemo(() => Object.keys(links ?? {}).length, [links])

  return (
    <>
      <SummaryHeader
        prefixIconName="stars"
        after={
          <IconPullRight onClick={onCloseHandler}>
            <SvgIcon
              iconName="xmark-large"
              size={24}
              color={theme.color.primary.primary01}
            />
          </IconPullRight>
        }>
        {title}
      </SummaryHeader>
      {question && <StyledQuestion>{question.text}</StyledQuestion>}
      <SummaryList
        inline
        links={links}
        trackingClickType="question_widget_click"
        clickHandler={setActiveIndex}
        summaries={content ?? []}
      />
      {links && (
        <SummarySlider
          activeIndex={activeIndex}
          headline={translate('summary.sources.title')}
          subheadline={
            linksCount > 1
              ? translate('summary.sources.subtitle.plural', {
                  totalElements: linksCount.toString(),
                })
              : translate('summary.sources.subtitle.singular')
          }
          slides={Object.keys(links).map((key) => {
            const item = links[key]
            const url = new URL(item.url)
            return (
              <SwiperSlide
                href={url.pathname}
                key={`source-item-${item.url}-${key}`}>
                <span>
                  {item.isPlus && (
                    <StyledPlusIcon
                      iconName="blick-plus-logo"
                      size={19}
                      color={theme.color.primary.primary01}
                    />
                  )}
                  {item.title}
                </span>
                <SummaryPill
                  trackingClickType="question_widget_click"
                  isActive={activeIndex === parseInt(key) - 1}>
                  {key}
                </SummaryPill>
              </SwiperSlide>
            )
          })}
          count={linksCount}
        />
      )}
      {children}
    </>
  )
}

export default QuestionSummaryResultView
