import config from '@config'
import { TAnswerResponse, TQuestionResponse, WidgetId } from './types'

const {
  chatbot: { baseUrl },
  sectionSummary: { answserUrl, questionsUrl },
} = config

export enum HTTP_METHOD {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

type ChatbotQueryApi = {
  widgetId: WidgetId
  context: string | number // number = articleId or special context e.g. "home"
}

type ChatbotQuery = ChatbotQueryApi & { question: boolean } // question = true -> get questions, false -> get answers

export const chatbotRequest = ({
  question,
  widgetId,
  context,
}: ChatbotQuery) => {
  const url = new URL(`${baseUrl}${question ? questionsUrl : answserUrl}`)
  url.searchParams.append('publication', 'blick')
  url.searchParams.append('widgetId', widgetId)
  url.searchParams.append('context', `${context}`)

  return url
}

/**
 * wraps an url into a request fn to be used in react-query
 */
const queryFn =
  <T>(url: URL, method: HTTP_METHOD = HTTP_METHOD.GET): (() => Promise<T>) =>
  async () => {
    const response = await fetch(url, {
      method,
    })

    if (response.status >= 400 && response.status < 600) {
      throw new Error(`Server responded with ${response.status}`)
    }

    return await response.json()
  }

export const getQuestions = ({ widgetId, context }: ChatbotQueryApi) => {
  return queryFn<TQuestionResponse>(
    chatbotRequest({ widgetId, context, question: true })
  )
}

export const getAnswers = ({
  widgetId,
  context,
  questionId,
}: ChatbotQueryApi & { questionId: string }) => {
  const url = chatbotRequest({ widgetId, context, question: false })
  url.searchParams.append('questionId', questionId)

  return queryFn<TAnswerResponse>(url)
}
