import styled, { css } from 'styled-components'
import { mobileCSS, tabletCSS, desktopCSS } from '@measures/responsive'
import Blink from '@components/Blink'
import {
  IMAGE_HEIGHT,
  IMAGE_HEIGHT_HERO,
} from '@components/TeaserStandardHorizontal/utils'

export interface StyledTeaserStandardHorizontalWrapperProps {
  imagePositionOnMobile: 'left' | 'right'
  isHybridTeaser: boolean
  isInHeroModule: boolean
}

const StyledTeaserStandardHorizontalWrapper = styled(
  Blink
)<StyledTeaserStandardHorizontalWrapperProps>`
  ${({
    theme: {
      spacing: { spacing16 },
    },
    imagePositionOnMobile,
    isHybridTeaser,
    isInHeroModule,
  }) => {
    const imageSize = isInHeroModule ? IMAGE_HEIGHT_HERO : IMAGE_HEIGHT
    return css`
      box-sizing: border-box;
      position: relative;
      ${mobileCSS(css`
                padding-${imagePositionOnMobile}: calc(${spacing16} + ${imageSize}px);
                min-height: ${imageSize}px;
            `)};

      ${tabletCSS(css`
                padding-${imagePositionOnMobile}: calc(${spacing16} + ${imageSize}px);
                min-height: ${imageSize}px;
            `)};

      ${desktopCSS(css`
        min-height: calc(134px);
        padding-left: calc(${spacing16} + 134px);
      `)};

      display: grid;
      align-items: flex-start;
      justify-items: flex-start;
      align-content: flex-start;
      justify-content: flex-start;
      grid-template-columns: minmax(0, 1fr);
      appearance: none;
      text-decoration: none;

      ${isHybridTeaser &&
      css`
        ${desktopCSS(css`
          display: none;
        `)}
      `}
    `
  }}
`

export default StyledTeaserStandardHorizontalWrapper
