import { FunctionComponent, useState } from 'react'
import dynamic from 'next/dynamic'
import HeadlineLeadComponents from '@components/HeadlineLead'
import config from '@config'
import styled, { css } from 'styled-components'
import Credit from '@components/ArticleTextbox/Credit'
import { desktopCSS, mobileCSS, tabletCSS } from '@measures/responsive'
import { HerotellingHeadlineLeadAPIProps } from '@widgets/HeadlineLead/types'
import { printCSS } from '@utils/style'
import usePageMetadata from '@hooks/usePageMetadata'
import TitleCatchword from '@components/HeadlineLead/TitleCatchword'
import SharingAndTextToSpeechWrapper from './SharingAndTextToSpeechWrapper'
import Social from '../Social'

const TextToSpeechPlayer = dynamic(
  () => import('@components/TextToSpeech/TextToSpeechPlayer'),
  { ssr: false }
)

const {
  publication: { publication },
} = config

const { ArticleLead, CommentButton, HeadlineHeroImage } = HeadlineLeadComponents

const StyledContentsWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing24, spacing16, spacing64 },
      color: {
        primary: { primary01: primary01Color },
      },
    },
  }) => css`
    grid-area: scw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-row: 2;
    padding: ${spacing24} ${spacing16} 0;
    text-align: left;
    background-color: ${primary01Color};

    ${tabletCSS(css`
      text-align: center;
      padding: ${spacing24} ${spacing64} 0;
    `)}

    ${desktopCSS(css`
      grid-row: auto;
      text-align: center;
      padding: 3vw;
    `)}
  `}
`

const HerotellingWrapper = styled.div`
  display: grid;
  width: 100%;

  ${desktopCSS(css`
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-areas: 'scw hhi';
  `)};

  ${tabletCSS(css`
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-template-areas:
      'hhi'
      'scw';
  `)};

  ${mobileCSS(css`
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-template-areas:
      'hhi'
      'scw';
  `)};
`

const SharingAndCommentWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => css`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    margin-bottom: -2px;
    margin-top: ${spacing8};
    align-items: center;
    width: 100%;

    ${desktopCSS(css`
      margin: 0;
      align-items: center;
    `)}

    ${printCSS(css`
      display: none;
    `)};
  `}
`

const HerotellingHeadlineLead: FunctionComponent<
  HerotellingHeadlineLeadAPIProps
> = ({
  catchword,
  title,
  lead,
  displayCommentsOnArticle,
  storytellingImage,
}) => {
  const [togglePlayer, setTogglePlayer] = useState(false)
  const pageMetadata = usePageMetadata()
  const articleUrl = pageMetadata?.url
  const articleId = pageMetadata?.id
  const formattedCredit = `Foto: ${storytellingImage?.credit?.text}`

  const shouldDisplayComments =
    displayCommentsOnArticle && publication !== 'romandie'

  return (
    <HerotellingWrapper>
      <StyledContentsWrapper>
        <TitleCatchword title={title} catchword={catchword}>
          {!!lead && <ArticleLead>{lead}</ArticleLead>}
        </TitleCatchword>
        <SharingAndTextToSpeechWrapper>
          <SharingAndCommentWrapper>
            {!articleUrl || !articleId ? null : (
              <Social
                endOfArticle={false}
                setTogglePlayer={setTogglePlayer}
                togglePlayer={togglePlayer}
              />
            )}
            {shouldDisplayComments && <CommentButton />}
          </SharingAndCommentWrapper>
          {togglePlayer && (
            <TextToSpeechPlayer setPlayerVisibility={setTogglePlayer} />
          )}
        </SharingAndTextToSpeechWrapper>
        <Credit>{formattedCredit}</Credit>
      </StyledContentsWrapper>
      <HeadlineHeroImage
        src={storytellingImage?.src}
        alt={storytellingImage?.alt}
        crops={storytellingImage?.crops}
      />
    </HerotellingWrapper>
  )
}

export default HerotellingHeadlineLead
