import { FunctionComponent } from 'react'
import type {
  ImageRatios,
  ImageWidths,
  ImageCrops,
} from '@components/ResponsiveImageV2/types'
import { Image } from '@cook-types/cook'
import { AUTHOR_IMAGE_RATIO, AUTHOR_IMAGE_SIZE } from '../utils'
import ResponsiveImageV2 from '@components/ResponsiveImageV2'
import styled from 'styled-components'

interface ImageWrapperProps {
  children: Required<Pick<Image<ImageCrops>, 'src' | 'alt'>> &
    Pick<Image<ImageCrops>, 'crops'>
  className?: string
}

const IMAGE_RATIOS: ImageRatios = {
  desktop: AUTHOR_IMAGE_RATIO,
  tablet: AUTHOR_IMAGE_RATIO,
  mobile: AUTHOR_IMAGE_RATIO,
}

const IMAGE_WIDTHS: ImageWidths = {
  desktop: AUTHOR_IMAGE_SIZE,
  tablet: AUTHOR_IMAGE_SIZE,
  mobile: AUTHOR_IMAGE_SIZE,
  mobileSmall: AUTHOR_IMAGE_SIZE,
}

const StyledWrapper = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.color.tertiary.grey200};

  /* Needed for Safari 14.1 */
  transform: translateZ(0);
`

const ImageWrapper: FunctionComponent<ImageWrapperProps> = ({
  children,
  className,
}) => {
  const { src, alt, crops } = children

  return (
    <StyledWrapper className={className}>
      <ResponsiveImageV2
        src={src}
        alt={alt}
        ratios={IMAGE_RATIOS}
        crops={crops ?? {}}
        widths={IMAGE_WIDTHS}
      />
    </StyledWrapper>
  )
}

export default ImageWrapper
