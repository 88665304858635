import PrimaryButton from '@components/Buttons/Primary'
import translate from '@i18n'
import { isMobileUserAgent, mobileCSS } from '@measures/responsive'
import { SearchInputProps } from '@widgets/Search/types'
import {
  createRef,
  FunctionComponent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import styled, { css, useTheme } from 'styled-components'
import Input from '@components/Input'
import SvgIcon from '@components/SvgIcon'

const StyledSearchInputContainer = styled.div`
  ${({
    theme: {
      spacing: { spacing40 },
    },
  }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    border: none;
    height: 48px;
    margin-bottom: ${spacing40};

    ${mobileCSS(css`
      flex-direction: column;
      height: unset;
      margin-bottom: 0;
    `)}
  `}
`

const StyledSearchButton = styled(PrimaryButton)`
  ${({
    theme: {
      color: {
        primary: { blickRed, primary02 },
        secondary: { darkRed },
      },
      spacing: { spacing4, spacing12, spacing16, spacing24 },
      typography: {
        subheads: {
          small1: { bundledCSS: smallSubHeadingCSS },
        },
      },
    },
  }) => css`
    padding: ${spacing12} ${spacing24};
    border-radius: 50px;
    width: 152px;
    height: 100%;
    justify-content: center;
    background-color: ${blickRed};
    text-transform: capitalize;
    color: ${primary02};

    ${smallSubHeadingCSS}
    &:hover {
      background-color: ${darkRed};
      color: ${primary02};
    }

    ${mobileCSS(css`
      padding: ${spacing4} ${spacing16};
      width: 100%;
      height: 40px;
    `)}
  `}
`

const StyledInput = styled(Input)`
  ${({
    theme: {
      spacing: { spacing8, spacing16 },
    },
  }) => css`
    flex-grow: 1;
    margin-right: ${spacing8};

    ${mobileCSS(css`
      flex-grow: unset;
      margin-right: unset;
      margin-bottom: ${spacing16};
    `)}
  `}
`

const IconWrapper = styled.button`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => css`
    background-color: transparent;
    border: 0;
    cursor: pointer;
    line-height: 0;
    justify-self: flex-end;
    padding: 0 ${spacing8};
  `}
`

const SearchInput: FunctionComponent<SearchInputProps> = ({
  initialValue,
  onSubmit,
}) => {
  const [internalValue, setInternalValue] = useState<string>('')
  const inputRef = createRef<HTMLInputElement>()
  const theme = useTheme()

  useEffect(() => {
    if (!!initialValue) {
      setInternalValue(initialValue)
    }
  }, [initialValue])

  const handleChange = (event: any) => {
    setInternalValue(event.target.value)
  }

  const handleClearIntervalValue = useCallback(() => {
    setInternalValue('')
  }, [])

  const handleSubmit = useCallback(
    (e?: SyntheticEvent) => {
      e?.stopPropagation?.()
      e?.preventDefault?.()
      if (isMobileUserAgent()) {
        inputRef?.current?.blur?.()
      }
      onSubmit(internalValue)
    },
    [internalValue, onSubmit, inputRef]
  )

  return (
    <form onSubmit={handleSubmit}>
      <StyledSearchInputContainer>
        <StyledInput
          internalValue={internalValue}
          ref={inputRef}
          onChange={handleChange}
          placeholder={translate('search.input')}>
          {!!internalValue && (
            <IconWrapper onClick={handleClearIntervalValue} type="button">
              <SvgIcon
                iconName="xmark-large"
                size={24}
                color={theme.color.primary.primary01}
              />
            </IconWrapper>
          )}
        </StyledInput>
        <StyledSearchButton onClick={handleSubmit} size="small" type="submit">
          {translate('search.defaultButtonTitle')}
        </StyledSearchButton>
      </StyledSearchInputContainer>
    </form>
  )
}
export default SearchInput
