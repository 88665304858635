import SvgIcon from '@components/SvgIcon'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { StyledCommonButtonProps, ButtonProps } from './types'

// TODO: refactor to use theme colors
const grey_50 = '#C8C8C8'
const grey_75 = '#767676'
const grey_90 = '#3D3D3D'
const focus = '#2460C5'

const StyledSecondaryButton = styled.button<StyledCommonButtonProps>`
  ${({
    theme: {
      typography: {
        subheads: {
          small1: { bundledCSS: small1SubheadsCSS },
        },
      },
      color: {
        primary: { primary02 },
      },
    },
    size,
    isDisabled,
  }) => css`
    ${small1SubheadsCSS};

    line-height: 20px;
    background-color: ${primary02};
    border-radius: ${size === 'small' ? '20px' : '24px'};
    color: ${grey_90};
    padding: ${size === 'small' ? '10px' : '14px'} 24px;
    border: 1.2px solid ${grey_90};
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    height: ${size === 'small' ? '40px' : '48px'};
    ${isDisabled
      ? css`
          border: 1.2px solid ${grey_50};
          color: ${grey_50};
          cursor: auto;
        `
      : css`
          &:hover {
            border: 1.2px solid ${grey_75};
            color: ${grey_75};
          }
          &:focus-visible {
            border: 4px solid ${focus};
          }
        `}
  `}
`

const SecondaryButton: FunctionComponent<ButtonProps> = ({
  size,
  withArrow,
  isDownArrow,
  children,
  onClick,
  isDisabled,
  className,
}) => {
  return (
    <StyledSecondaryButton
      size={size}
      isDisabled={isDisabled}
      onClick={!isDisabled ? onClick : () => {}}
      className={className}>
      {children}
      {withArrow && (
        <SvgIcon
          iconName={isDownArrow ? 'chevron-down' : 'chevron-up'}
          size={20}
          //convert hard coded colors to variables coming from theme
          color={isDisabled ? grey_50 : grey_90}
        />
      )}
    </StyledSecondaryButton>
  )
}

export default SecondaryButton
