import MinimalHockeySoccerFooter from '@components/SportsFooter/MinimalHockeySoccer'
import SportLabelContent from '@components/SportsFooter/SportLabelContent'
import { transformDataForSportsComponents } from '@components/SportsFooter/utils'
import BlickPlusLogo from '@components/TeaserStandardHorizontal/BlickPlusLogo'
import Catchword from '@components/TeaserStandardHorizontal/Catchword'
import ImageComponent from '@components/TeaserStandardHorizontal/Image'
import Label from '@components/TeaserStandardHorizontal/Label'
import PromoLabel from '@components/TeaserStandardHorizontal/PromoLabel'
import SponsoredLabel from '@components/TeaserStandardHorizontal/SponsoredLabel'
import SponsoredLogo from '@components/TeaserStandardHorizontal/SponsoredLogo'
import Title from '@components/TeaserStandardHorizontal/Title'
import VideoLabel from '@components/VideoLabel'
import useIsHybridTeaser from '@hooks/useIsHybridTeaser'
import useIsRecommendationTeaser from '@hooks/useIsRecommendationTeaser'
import useSportsEvent from '@hooks/useSportsEvent'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import { stripHtml } from '@hooks/useTracking/utils'
import useViewportTracking from '@hooks/useViewportTracking'
import { desktopCSS, mobileCSS, tabletCSS } from '@measures/responsive'
import { useQueryClient } from '@tanstack/react-query'
import { triggerAureusImpression } from '@utils/aureus'
import { Image, Link, TargetContentType, TeaserVideo } from '@utils/cook/types'
import { LIVE_EVENT_DATA_SPORT_TYPES } from '@utils/formatters/common'
import { LiveEventDataSportTypes } from '@utils/formatters/liveEvent'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent, useCallback } from 'react'
import styled, { css, useTheme } from 'styled-components'
import TeaserAnimatedPreview from '@components/TeaserAnimatedPreview'
import useAnimatedPreview from '@hooks/useAnimatedPreview'
import useRefDelegate from '@hooks/useRefDelegate'
import StyledTeaserStandardHorizontalWrapper from '@components/TeaserStandardHorizontal/Wrapper'
import useClientViewportType from '@hooks/useClientViewportType'
import useIsInHeroModule from '@hooks/useIsInHeroModule'
import {
  IMAGE_HEIGHT,
  IMAGE_HEIGHT_HERO,
} from '@components/TeaserStandardHorizontal/utils'
import useGetPageIdentity from '@hooks/useGetPageIdentity'

export interface TeaserStandardHorizontalCommonProps {
  accentColor: string
  title: string
  catchword?: string
  imagePositionOnMobile?: 'right'
  isPlus?: boolean
  articleId?: string
  targetContentType: TargetContentType
  track?: boolean
  link: Link
  image: Image<'1_1'>
  section: {
    name: string
    uniqueName: string
  }
  contentOrigin?: string
  video?: TeaserVideo
  aureusOfferId?: string
}

export interface TeaserStandardHorizontalNoCommercialProps {
  commercialType?: undefined
  labelText?: string
}

export interface TeaserStandardHorizontalPromoCommercialProps {
  commercialType: 'promo'
  commercial: {
    label: string
  }
}

export interface TeaserStandardHorizontalSponsoredCommercialProps {
  commercialType: 'sponsored' | 'editorial'
  commercial: {
    label: string
    logo?: Image<'free'>
  }
}

export type TeaserStandardHorizontalMaybeCommercialProps =
  | TeaserStandardHorizontalNoCommercialProps
  | TeaserStandardHorizontalPromoCommercialProps
  | TeaserStandardHorizontalSponsoredCommercialProps

export interface TeaserStandardHorizontalNoSportProps {
  typeOfSport?: undefined
}

export interface TeaserStandardHorizontalSportProps {
  typeOfSport: Extract<LiveEventDataSportTypes, 'soccer' | 'hockey'>
  matchId: string
}

export type TeaserStandardHorizontalMaybeSportProps =
  | TeaserStandardHorizontalNoSportProps
  | TeaserStandardHorizontalSportProps

export type TeaserStandardHorizontalAPIProps =
  TeaserStandardHorizontalCommonProps &
    TeaserStandardHorizontalMaybeCommercialProps &
    TeaserStandardHorizontalMaybeSportProps

export interface StyledImageComponentProps {
  hasBorder: boolean
  imagePositionOnMobile: 'left' | 'right'
  imageSize: number
}

const StyledImageComponent = styled(ImageComponent)<StyledImageComponentProps>`
  ${({
    theme: {
      spacing: { spacing4 },
      color: {
        primary: { primary02: primary02Color },
      },
    },
    hasBorder,
    imagePositionOnMobile,
    imageSize,
  }) => css`
    position: absolute;
    top: 0;

    ${mobileCSS(css`
      width: ${imageSize}px;
      padding-bottom: ${imageSize}px;
      ${imagePositionOnMobile}: 0;
    `)};

    ${tabletCSS(css`
      width: ${imageSize}px;
      padding-bottom: ${imageSize}px;
      ${imagePositionOnMobile}: 0;
    `)};

    ${desktopCSS(css`
      width: 134px;
      padding-bottom: 134px;
      left: 0;
    `)};

    ${hasBorder &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: ${spacing4};
        right: ${spacing4};
        bottom: ${spacing4};
        left: ${spacing4};
        border: 1px solid ${primary02Color};
        z-index: 1;
      }
    `}
  `};
`

const StyledVideoLabel = styled(VideoLabel)`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => css`
    position: absolute;
    bottom: ${spacing8};
    left: ${spacing8};
  `}
`

const StyledMinimalSportsFooter = styled(MinimalHockeySoccerFooter)`
  position: absolute;
  bottom: 0;
  left: 0;
`

const TeaserStandardHorizontal: FunctionComponent<
  TeaserStandardHorizontalAPIProps
> = (props) => {
  const {
    accentColor,
    title,
    link,
    image,
    isPlus,
    catchword,
    commercialType,
    imagePositionOnMobile,
    targetContentType,
    typeOfSport,
    articleId,
    contentOrigin,
    track,
    video,
    aureusOfferId,
  } = props

  const {
    color: {
      secondary: { greenAccessible },
    },
  } = useTheme()
  const isEditorial = commercialType === 'editorial'

  const isCommercial = !!commercialType

  const hasBorder = isCommercial && !isEditorial

  const isPromo = commercialType === 'promo'

  const isSponsoredOrEditorial = isCommercial && !isPromo

  const showCatchword = !!isPlus || !!catchword

  const isHybridTeaser = useIsHybridTeaser()

  const clientViewportType = useClientViewportType()

  const { isRecommendation: isRecommendationTeaser, placement } =
    useIsRecommendationTeaser()

  const isValidSport =
    !!typeOfSport &&
    (
      [
        LIVE_EVENT_DATA_SPORT_TYPES.SOCCER,
        LIVE_EVENT_DATA_SPORT_TYPES.HOCKEY,
      ] as LiveEventDataSportTypes[]
    ).includes(typeOfSport)

  const { data: sportsEventData } = useSportsEvent({
    matchId: isValidSport ? props.matchId : '',
    typeOfSport: isValidSport ? typeOfSport : 'NO_SPORT',
    shouldRefetch: true,
  })

  const sportsData = sportsEventData
    ? transformDataForSportsComponents(sportsEventData)
    : undefined

  const shouldShowSportsComponents =
    sportsData?.typeOfSport === LIVE_EVENT_DATA_SPORT_TYPES.SOCCER ||
    sportsData?.typeOfSport === LIVE_EVENT_DATA_SPORT_TYPES.HOCKEY

  const labelColor = sportsData?.isLive
    ? undefined
    : isValidSport
      ? greenAccessible
      : accentColor

  const hasLabelText = !isCommercial && !!props.labelText

  const normalizedImagePositionOnMobile = imagePositionOnMobile ?? 'left'

  const isInHeroModule = useIsInHeroModule()

  const queryClient = useQueryClient()

  const onImpression = useCallback<TrackingFnType>(
    () => ({
      event: 'teaser_impression',
      eventCategory: video ? 'video_teaser' : 'article_teaser',
      eventLabel: `${articleId}:${stripHtml(title)}`,
      eventAction: 'impression',
      id: articleId,
      is_branded: !!contentOrigin,
    }),
    [articleId, contentOrigin, title, video]
  )

  const onPromoImpression = useCallback<TrackingFnType>(
    () => ({
      event: 'contentcommerce_impression',
      eventCategory: 'BrandStudio',
      eventAction: 'Impression / Brandstudio',
    }),
    []
  )

  const onClick = useCallback<TrackingFnType>(
    () => ({
      event: 'teaser_click',
      eventCategory: video ? 'video_teaser' : 'article_teaser',
      eventLabel: `${articleId}:${stripHtml(title)}`,
      eventAction: 'click',
      id: articleId,
      is_branded: !!contentOrigin,
    }),
    [articleId, contentOrigin, title, video]
  )

  const onRecommendationClick = useCallback<TrackingFnType>(() => {
    return {
      event: 'select_content',
      placement: `article-${placement}`,
      link_id: link?.href,
    }
  }, [placement, link?.href])

  const trackedOnImpression = useTracking(onImpression)
  const trackedOnPromoImpression = useTracking(onPromoImpression)
  const trackedOnClick = useTracking(onClick)
  const trackedOnRecommendationClick = useTracking(onRecommendationClick)

  const { isHomePage } = useGetPageIdentity()
  const shouldBeTracked = isHomePage || !!contentOrigin || track

  const finalOnImpression = useCallback(() => {
    triggerAureusImpression({
      aureusOfferId,
      queryClient,
    })

    if (shouldBeTracked) {
      if (!isRecommendationTeaser) {
        trackedOnImpression()
      }
      if (commercialType) {
        trackedOnPromoImpression()
      }
    }
  }, [
    aureusOfferId,
    commercialType,
    queryClient,
    shouldBeTracked,
    trackedOnImpression,
    trackedOnPromoImpression,
    isRecommendationTeaser,
  ])

  const clickableProps = {
    aureusOfferId,
    ...(shouldBeTracked
      ? {
          onClick: isRecommendationTeaser
            ? trackedOnRecommendationClick
            : trackedOnClick,
        }
      : {}),
    ...link,
  }

  const ref = useRefDelegate(
    useAnimatedPreview({
      video,
      articleUrl: clickableProps.href,
    }),
    useViewportTracking({
      onImpression: finalOnImpression,
      track: true,
    })
  )

  if (isHybridTeaser && clientViewportType === 'desktop') {
    return null
  }

  const imageSize = isInHeroModule ? IMAGE_HEIGHT_HERO : IMAGE_HEIGHT

  return (
    <StyledTeaserStandardHorizontalWrapper
      {...clickableProps}
      imagePositionOnMobile={normalizedImagePositionOnMobile}
      isHybridTeaser={isHybridTeaser}
      isInHeroModule={isInHeroModule}
      ref={ref}>
      <StyledImageComponent
        {...image}
        hasBorder={hasBorder}
        imagePositionOnMobile={normalizedImagePositionOnMobile}
        imageSize={imageSize}>
        {!!video && (
          <TeaserAnimatedPreview video={video} articleUrl={link.href} />
        )}
        {!!props.video && (
          <StyledVideoLabel
            duration={props.video.duration}
            targetContentType={targetContentType}
          />
        )}
        {shouldShowSportsComponents && (
          <StyledMinimalSportsFooter sportsData={sportsData} />
        )}
      </StyledImageComponent>
      {isPromo && <PromoLabel>{props.commercial.label}</PromoLabel>}
      {(hasLabelText || isValidSport) && (
        <Label labelColor={labelColor}>
          {shouldShowSportsComponents ? (
            <SportLabelContent sportsData={sportsData} />
          ) : hasLabelText ? (
            props.labelText
          ) : null}
        </Label>
      )}
      {isSponsoredOrEditorial && (
        <SponsoredLabel>
          {props.commercial.label}
          {!!props.commercial.logo && (
            <SponsoredLogo {...props.commercial.logo} />
          )}
        </SponsoredLabel>
      )}
      {showCatchword && (
        <Catchword hasPlusLogo={!!isPlus}>
          {isPlus && <BlickPlusLogo />}
          {catchword}
        </Catchword>
      )}
      <Title>{title}</Title>
    </StyledTeaserStandardHorizontalWrapper>
  )
}

const widget = {
  kind: ['teaser-standard-horizontal'],
  component: TeaserStandardHorizontal,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
