import { FunctionComponent, useMemo, useState } from 'react'
import styled, { css, useTheme } from 'styled-components'
import translate from '@i18n'
import SummaryHeader from '@components/Summary/SummaryHeader'
import SvgIcon from '@components/SvgIcon'
import { SummarySlider } from '@components/Summary/SummarySlider'
import SummaryPill from '@components/Summary/SummaryPill'
import SummaryList from '@components/Summary/SummaryList'
import SummaryDisclaimer, {
  type SummaryDisclaimerProps,
} from '@components/Summary/SummaryDisclaimer'
import { SectionSummaryProps } from './types'
import { SwiperSlide } from '@components/Summary/SummarySliderComponents'
import { TrackingClickType } from './utils'

const IconPullRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: right;
  width: 100%;
  cursor: pointer;
`

const StyledPlusIcon = styled(SvgIcon)`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => {
    return css`
      vertical-align: text-bottom;
      margin-right: ${spacing8};
    `
  }}
`

const SummaryResultView: FunctionComponent<
  Omit<SectionSummaryProps, 'kind'> & {
    activeIndex?: number
    onCloseHandler: () => void
    disclaimer: SummaryDisclaimerProps
    trackingClickType: TrackingClickType
  }
> = ({
  title,
  links,
  content,
  disclaimer,
  onCloseHandler,
  activeIndex: initialActiveIndex,
  trackingClickType,
}) => {
  const theme = useTheme()
  const [activeIndex, setActiveIndex] = useState<number | undefined>(
    initialActiveIndex
  )
  const linksCount = useMemo(() => Object.keys(links ?? {}).length, [links])

  return (
    <>
      <SummaryHeader
        prefixIconName="stars"
        after={
          <IconPullRight onClick={onCloseHandler}>
            <SvgIcon
              iconName="xmark-large"
              size={24}
              color={theme.color.primary.primary01}
            />
          </IconPullRight>
        }>
        {title}
      </SummaryHeader>

      {Array.isArray(content) && content?.length > 0 && (
        <SummaryList
          summaries={content ?? []}
          links={links}
          clickHandler={setActiveIndex}
          trackingClickType={trackingClickType}
        />
      )}

      {links && (
        <SummarySlider
          activeIndex={activeIndex}
          headline={translate('summary.sources.title')}
          subheadline={
            linksCount > 1
              ? translate('summary.sources.subtitle.plural', {
                  totalElements: linksCount.toString(),
                })
              : translate('summary.sources.subtitle.singular')
          }
          slides={Object.keys(links).map((key) => {
            const item = links[key]
            const url = new URL(item.url)
            return (
              <SwiperSlide
                href={url.pathname}
                key={`source-item-${item.url}-${key}`}>
                <span>
                  {item.isPlus && (
                    <StyledPlusIcon
                      iconName="blick-plus-logo"
                      size={19}
                      color={theme.color.primary.primary01}
                    />
                  )}
                  {item.title}
                </span>
                <SummaryPill
                  trackingClickType={trackingClickType}
                  isActive={activeIndex === parseInt(key) - 1}>
                  {key}
                </SummaryPill>
              </SwiperSlide>
            )
          })}
          count={linksCount}
        />
      )}

      <SummaryDisclaimer {...disclaimer} />
    </>
  )
}

export default SummaryResultView
