import { FunctionComponent, useCallback } from 'react'
import ApiContextProvider from '@contexts/commenting/apiContextProvider'
import GenericContext from '@contexts/commenting/genericContext'
import CommentingWidget from './CommentingWidget'
import { CommentingProps } from './types'
import useUser from '@hooks/useUser'
import useAuthentication from '@hooks/useAuthentication'
import usePageMetadata from '@hooks/usePageMetadata'
import useSubscriptionStatus from '@hooks/useSubscriptionStatus'
import { scrollToCommentingWidget } from './utils'
import { useQueryClient } from '@tanstack/react-query'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import config from '@config'

const {
  publication: { publication },
} = config

const Commenting: FunctionComponent<CommentingProps> = ({
  commentsEnabled,
  id,
  postingEnabled,
  title,
}) => {
  const userData = useUser()
  const queryClient = useQueryClient()
  const { login } = useAuthentication()
  const onLogin = useCallback(() => login({ prefix: 'COM' }), [login])
  const { subscriptionStatus } = useSubscriptionStatus()
  const { teaser } = usePageMetadata()

  const showBlickPlusInfoBox =
    !!teaser?.isPlus && subscriptionStatus !== 'subscribed'

  const showPrompts = !!teaser?.isPlus
    ? postingEnabled && !!teaser?.isPlus && subscriptionStatus === 'subscribed'
    : postingEnabled

  const requireFullLogin = (action: () => void) => {
    userData?.email_only !== false ? onLogin() : action()
  }

  const requireSubscribedUser = (action: () => void) => {
    subscriptionStatus !== 'subscribed'
      ? scrollToCommentingWidget(queryClient)
      : requireFullLogin(action)
  }

  const requireValidUserForCommentReactions = (action: () => void) => () => {
    return requireFullLogin(action)
  }

  const requireValidUser = (action: () => void) => () => {
    return (teaser?.isPlus ? requireSubscribedUser : requireFullLogin)(action)
  }

  if (publication === 'romandie') {
    return null
  }

  return (
    <GenericContext.Provider
      value={{
        articleId: id,
        commentsEnabled: commentsEnabled === 'yes',
        requireValidUser,
        requireValidUserForCommentReactions,
        postingEnabled,
        showPrompts,
        showBlickPlusInfoBox,
        title,
      }}>
      <ApiContextProvider>
        <CommentingWidget />
      </ApiContextProvider>
    </GenericContext.Provider>
  )
}

const widget = {
  kind: ['widget', 'commenting'],
  component: Commenting,
} as const satisfies CookWidget
export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
