import SvgIcon from '@components/SvgIcon'
import { forwardRef } from 'react'
import styled, { css, useTheme } from 'styled-components'
import { ButtonProps, StyledCommonButtonProps } from './types'

// TODO: refactor to use theme colors
const grey_30 = '#E0E0E0'
const grey_70 = '#8E8E90'
const grey_80 = '#545454'
const grey_90 = '#3D3D3D'
const focus = '#2460C5'

const StyledPrimaryButton = styled.button<StyledCommonButtonProps>`
  ${({
    theme: {
      typography: {
        subheads: {
          small1: { bundledCSS: small1SubheadsCSS },
        },
      },
      color: {
        primary: { primary02 },
      },
    },
    size,
    disabled,
  }) => css`
    ${small1SubheadsCSS};

    line-height: 20px;
    background-color: ${grey_90};
    border-radius: ${size === 'small' ? '20px' : '24px'};
    color: ${primary02};
    padding: ${size === 'small' ? '10px' : '14px'} 24px;
    border: 0;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    height: ${size === 'small' ? '40px' : '48px'};
    ${disabled
      ? css`
          background: ${grey_30};
          color: ${grey_70};
          cursor: auto;
        `
      : css`
          &:hover {
            background-color: ${grey_80};
          }
          &:focus-visible {
            border: 4px solid ${focus};
          }
        `}
  `}
`

const PrimaryButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      size,
      withArrow,
      isDownArrow,
      children,
      onClick,
      isDisabled,
      className,
      type = 'button',
    },
    ref
  ) => {
    const theme = useTheme()
    return (
      <StyledPrimaryButton
        ref={ref}
        className={className}
        size={size}
        disabled={isDisabled}
        onClick={onClick}
        type={type}>
        {children}
        {withArrow && (
          <SvgIcon
            iconName={isDownArrow ? 'chevron-down' : 'chevron-up'}
            size={20}
            color={isDisabled ? grey_70 : theme.color.primary.primary02}
          />
        )}
      </StyledPrimaryButton>
    )
  }
)

PrimaryButton.displayName = 'PrimaryButton'

export default PrimaryButton
